.player {
  $root: &;

  &--centered {
    text-align: center;
  }

  &--inline {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  &--disconnected {
    opacity: 0.6;
  }

  &--onClick {
    cursor: pointer;
  }

  // &__content {
  // }

  &__name {
    flex: 0 1 auto;
    font-weight: 600;
    text-shadow: $text-shadow-1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__flag {
    vertical-align: top;
    margin-right: 4px;
    display: inline-block;
    margin-left: -8px;

    #{$root}--inline & {
      margin-left: 0;
    }
  }

  &__text {
    #{$root}--inline & {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 5px;
      align-items: center;
    }
  }

  &__score {
    flex: 0 0 auto;
    opacity: 0.6;
    margin-top: 2px;
    text-shadow: $text-shadow-1;

    #{$root}--inline & {
      margin-top: 0;
    }
  }

  &__gem {
    position: relative;
    flex: 0 0 auto;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    #{$root}--inline & {
      margin-right: 14px;
      margin-bottom: 0;

      @media screen and (max-width: $br-m) {
        margin-right: 8px;
      }
    }
  }

  &__gem-loading {
    position: absolute;
    z-index: 5;
  }

  &__timer {
    margin-top: 40px;

    #{$root}--inline & {
      margin-top: 0;
      margin-left: auto;
    }
  }

  &__turn {
    #{$root}--centered & {
      margin-top: 10px;
    }
  }
}
