.nav {
  $root: &;

  position: fixed;
  z-index: $z-nav;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: grid;
  align-content: start;
  box-sizing: border-box;
  pointer-events: none;
  padding: 40px $side;
  @include side('row-gap');
  grid-template-columns: auto 1fr auto;

  &--desktop {
    right: var(--leaderboard-side-width);
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'menu . logo'
      'account . .'
      '. . .';
  }

  &--landscape {
    @include side('padding');
    justify-items: center;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'menu logo logo'
      '. . .'
      'account . leaderboard';
  }

  &--portrait {
    @include side('padding-left');
    @include side('padding-right');
    @include side('padding-top');
    padding-bottom: 10px;
    justify-items: center;
    align-items: start;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'menu logo logo'
      '. . .'
      'account leaderboard . ';

    &#{$root}--menuState {
      &:after {
        display: block;
      }
    }
  }

  &__button {
    position: relative;
    z-index: 2;
    pointer-events: auto;
    text-align: center;

    &--account {
      grid-area: account;

      #{$root}--portrait & {
        justify-self: start;
      }
    }

    &--leaderboard {
      grid-area: leaderboard;
    }

    &--menu {
      grid-area: menu;

      #{$root}--portrait & {
        justify-self: start;
      }
    }
  }

  &__logo-wrapper {
    pointer-events: auto;
    grid-area: logo;
    display: flex;
    align-items: center;
    align-self: start;
    justify-self: end;
  }

  &__logo-img {
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    justify-self: end;
    margin-left: 20px;

    img {
      flex: 0 0 auto;
    }
  }

  &__social-button {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  &__social-button-text {
    display: none;

    #{$root}--desktop & {
      display: block;
    }
  }
}
