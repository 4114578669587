.link {
  $root: &;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  grid-gap: 6px;

  &:hover {
    opacity: 0.8;
  }

  &--text {
    text-decoration: underline;
  }
  &--flat {
  }
}
