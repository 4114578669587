.leaderboard {
  $root: &;

  &--side {
    position: fixed;
    z-index: $z-leaderboard;
    top: 0;
    right: 0;
    width: var(--leaderboard-side-width);
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 0;
    box-shadow: $box-shadow-2;
  }

  &--page {
  }

  &__panel {
  }

  &__wrap {
  }

  &__h {
    margin-bottom: 22px;
  }

  &__table-wrap {
    #{$root}--side & {
      margin-left: -30px;
      margin-right: -40px;
    }

    #{$root}--page & {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &__table {
    text-align: left;
    table-layout: fixed;
    width: 100%;

    th {
      opacity: 0.5;
    }

    td,
    th {
      padding-top: 7px;
      padding-bottom: 7px;
      position: relative;
    }
  }

  &__item {
    background-color: rgba($c-dark, 0.6);
    border-top: 1px solid $c-dark;

    &:nth-child(even) {
      background-color: rgba($c-stone, 0.6);
      border-top: 1px solid $c-stone;
    }
  }

  &__crown {
    position: absolute;
    @include size(14px);
    left: 8px;
    top: 50%;
    margin-top: -7px;
  }

  &__flag {
    vertical-align: middle;
    margin-right: 4px;
    display: inline-block;
    margin-left: -16px;
  }

  &__rank {
    width: 60px;

    @media screen and (max-width: $br-375) {
      width: 55px;
    }

    @at-root th#{&} {
      text-align: center;
    }

    @at-root td#{&} {
      padding-left: 30px;
      padding-right: 16px;
    }
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 16px;
  }

  &__score {
    width: 36px;
    text-align: right;
    padding-left: 8px;
    padding-right: 16px;
  }
}
