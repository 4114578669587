.announcement {
  $root: &;
  width: 100%;
  max-width: 450px;

  &__h {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 8px;
  }

  &__social {
    display: grid;
    grid-gap: 4px;
  }
}
